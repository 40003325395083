  // router.js or similar
  import { createRouter, createWebHistory } from 'vue-router';
  import LoginModal from './components/LoginModal.vue';

  const routes = [
    {
      path: '/auth/:auth_token?',
      component: LoginModal,
      props: true
    },
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  export default router;
