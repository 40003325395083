<template>
    <div class="magic-login-input">
        <InputText ref="emailInput" v-model="email" type="email" placeholder="Enter your email" :disabled="isDisabled"
            @keyup.enter="submitEmail" />
        <p v-if="message" class="message">{{ message }}</p>

    </div>
</template>
  
  
<script>
import InputText from 'primevue/inputtext';

export default {
    name: 'MagicLoginInput',
    components: {
        InputText
    },
    props: {
        message: String,
        isDisabled: Boolean
    },
    data() {
        return {
            email: ''
        };
    },
    mounted() {
        this.focusEmailInput();
    },
    methods: {
        focusEmailInput() {
            this.$nextTick(() => {
                if (this.$refs.emailInput && this.$refs.emailInput.$el) {
                    this.$refs.emailInput.$el.focus();
                }
            });
        },
        submitEmail() {
            this.$emit('submit-email', this.email);
        }
    }
};

</script>
  
<style scoped>
.magic-login-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.magic-login-input .p-inputtext {
    width: 100%;
    max-width: 300px;
}

.magic-login-input .message {
    color: var(--primary-color);
    /* Update this color as per your requirement */
}
</style>

  