<template>
  <footer class="footer-static">
    ©2024 <a href="https://continualbot.com" target="_blank" class="footer-link">ContinualBot</a> | v1.2 Beta 240204
  </footer>
</template>
  
<script>
export default {
  name: 'FooterStatic'
};
</script>

<style scoped>
.footer-static {
  font-size: 11px;
  width: 100%;
  text-align: center;
  padding: 10px;
  background-color: black;
  color: var(--text-color);
  position: fixed;
  bottom: 0;
}

.footer-link {
  color: inherit;
  text-decoration: none;
}

.footer-link:hover {
  color: var(--secondary-color);
}

@media (max-width: 480px) {
  .footer-static {
    font-size: smaller;
    padding: 5px;
  }
}
</style>