<template>
    <div class="ham-menu">
        <Button type="button" icon="pi pi-ellipsis-v" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" />
        <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
        <TermsAndConditionsModal :visible="showTermsModal" @update:visible="showTermsModal = $event" />
        <MyAccountModal :visible="showMyAccountModal" @update:visible="showMyAccountModal = $event" />
    </div>
</template>

<script setup>
import Menu from 'primevue/menu';
import Button from 'primevue/button';
import TermsAndConditionsModal from './TermsAndConditionsModal.vue';
import MyAccountModal from './MyAccountModal.vue';
import { ref, getCurrentInstance } from "vue";

const { proxy } = getCurrentInstance();


// Method to handle log-out
const handleLogout = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('conversation_id');
    localStorage.removeItem('user_details');
    proxy.$emit('logout');
    window.location.reload();
};


const menu = ref();
const showTermsModal = ref(false);
const showMyAccountModal = ref(false);


const items = ref([
    {
        label: 'Open',
        items: [
            {
                label: 'Website',
                icon: 'pi pi-external-link',
                command: () => window.open('https://continualbot.com', '_blank')
            },
            {
                label: 'Terms and conditions',
                icon: 'pi pi-info-circle',
                command: () => { showTermsModal.value = true; }
            },
            {
                label: 'My Account',
                icon: 'pi pi-user',
                command: () => { showMyAccountModal.value = true; }
            },
            {
                label: 'Log out',
                icon: 'pi pi-refresh',
                command: handleLogout
            }
        ]
    }
]);

const toggle = (event) => {
    menu.value.toggle(event);
};
</script>

<style scoped>
.p-button {
    background-color: var(--primary-color);
}

.ham-menu {
    position: fixed;
    bottom: 65px;
    left: 30px;
    z-index: 1000;
}

@media (max-width: 480px) {
    .ham-menu {
        left: 10px;
    }
}
</style>