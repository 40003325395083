<template>
  <div class="prompt-area">
    <Textarea v-model="value" autoResize class="message-input" cols="30" placeholder="Type a message..."
      @keydown="sendMessageEnter" />
    <Button label="Send" class="send-button" @click="sendMessageButton" />
  </div>
</template>

<script>
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import { ref, getCurrentInstance } from 'vue';
import axios from 'axios';


export default {
  name: 'PromptArea',
  components: {
    Textarea,
    Button
  },
  emits: ['sendMessage', 'receiveMessage'],

  setup(props, { emit }) {
    const value = ref('');
    const instance = getCurrentInstance();
    const apiBaseUrl = instance.appContext.config.globalProperties.$config.apiBaseUrl;

    const postMessage = async (message) => {
      const jwt = localStorage.getItem('jwt');
      const conversation_id = localStorage.getItem('conversation_id');
      try {
        const config = {
          headers: {
            'Authorization': `Bearer ${jwt}`
          }
        };
        const payload = {
          prompt: message,
          conversation_id,
          message_type: 'user',
          message_category: 'prompt',
          message_time: new Date()
        };
        const response = await axios.post(apiBaseUrl + '/prompt', payload, config);
        emit('receiveMessage', response.data)
        console.log(response.data);
      } catch (error) {
        console.error('Error posting message:', error);
      }
    };
    const sendMessage = () => {
      if (value.value.trim()) {
        emit('sendMessage', value.value);
        postMessage(value.value);
        value.value = '';
      }
    }
    const sendMessageEnter = (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // Prevent the default Enter key behavior
        sendMessage();
      }
    };
    const sendMessageButton = () => {
      sendMessage();
    };
    return {
      value, sendMessageEnter, sendMessageButton
    };
  }
};
</script>

<style scoped>
.prompt-area {
  font-size: var(--text-font-size);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: var(--prompt-area-height);
  left: 0;
  right: 0;
  padding: 10px;
  box-sizing: border-box;
}

.message-input {
  width: calc(60% - var(--button-width) - 10px);
  min-height: var(--prompt-area-height);
  margin-right: 10px;
  font-size: var(--text-font-size);
}

.send-button {
  font-size: var(--text-font-size);
  width: var(--button-width);
  background-color: var(--primary-color);
  color: black;
}

@media (max-width: 480px) {


  .prompt-area {
    justify-content: space-between;
  }

  .message-input {
    width: auto;
    margin-left: 55px;
    flex-grow: 1;
  }

  .send-button {
    width: var(--button-width)/2;
  }

}
</style>
