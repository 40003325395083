<template>
  <div id="app">
    <HeaderLogo />
    <LoginModal :visible="!isAuthenticated" @login-success="handleLoginSuccess" @login-failure="handleLoginFailure" />
    <Toast />
    <ChatWindow v-if="isAuthenticated" :messages="messages" :current_user_id="user_id" />
    <PromptArea @sendMessage="handleSendMessage" @receiveMessage="handleReceivedMessage" />
    <ham-menu></ham-menu>
    <footer-static></footer-static>
  </div>
</template>

<style>
@import './assets/global.css';

/* Add global styles for blurring the background when modal is visible */
.modal-open .app-content {
  filter: blur(4px);
}
</style>

<script>
import HeaderLogo from './components/HeaderLogo.vue';
import Toast from 'primevue/toast';
import LoginModal from './components/LoginModal.vue';
import FooterStatic from './components/FooterStatic.vue';
import HamMenu from './components/HamMenu.vue';
import PromptArea from './components/PromptArea.vue';
import ChatWindow from './components/ChatWindow.vue';
import axios from 'axios';


export default {
  name: 'App',
  components: {
    HeaderLogo,
    FooterStatic,
    HamMenu,
    PromptArea,
    ChatWindow,
    Toast,
    LoginModal
  },
  data() {
    return {
      // Example user ID (this would typically come from your authentication logic)
      isAuthenticated: false,
      user_id: null,
      conversation_id: null,
      messages: []
    };
  },
  created() {
    const jwt = localStorage.getItem('jwt');
    this.conversation_id = localStorage.getItem('conversation_id'); // Retrieve conversation_id

    if (jwt && this.isJwtValid(jwt)) {
      this.isAuthenticated = true;
      this.user_id = this.parseJwt(jwt).user_id; // Assuming JWT contains userId
      this.fetchMessages(); // Fetch messages after page refresh
    }
  },
  methods: {
    async fetchMessages() {
      try {
        const jwt = localStorage.getItem('jwt');
        if (!jwt || !this.conversation_id) return;

        const config = {
          headers: {
            'Authorization': `Bearer ${jwt}`,
            'conversation_id': this.conversation_id
          }
        };
        const response = await axios.get(this.$config.apiBaseUrl + '/conversation', config);
        this.messages = response.data.map(msg => ({
          ...msg
        }));
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },
    handleLoginSuccess(response) {
      // Log the response for debugging
      console.log("Login response:", response);

      // Check if the response data is structured as expected
      if (!response || !response.jwt || !response.user_details || !response.conversation) {
        console.error('Login response is not in the expected format:', response);
        return;
      }

      const { jwt, user_details, conversation_id, conversation } = response;

      // Save JWT and user details to local storage
      localStorage.setItem('jwt', jwt);
      localStorage.setItem('user_details', JSON.stringify(user_details));
      localStorage.setItem('conversation_id', conversation_id);


      // Update isAuthenticated and user_id
      this.isAuthenticated = true;
      this.user_id = user_details.id; // Assuming the user's ID is in the user_details object

      // Populate messages
      this.messages = conversation.map(msg => ({
        message_id: msg.message_id,
        message: msg.message,
        message_type: msg.message_type,
        message_category: msg.message_category,
        user_id: msg.user_id,
        message_time: msg.message_time
      }));
      this.fetchMessages();

    },
    handleLoginFailure() {
      console.log('Login failed');
    },
    mounted() {
      this.isAuthenticated = !!localStorage.getItem('jwt');
    },
    handleSendMessage(newMessageText) {
      const newMessage = {
        // message_id: Date.now(), // or another unique identifier
        message: newMessageText,
        conversation_id: this.conversation_id,
        message_type: 'user',
        message_category: 'prompt',
        user_id: this.user_id,
        message_time: new Date() // Current timestamp
      };
      this.messages.unshift(newMessage);
    },
    handleReceivedMessage(responseData) {
      // Add the response as a system message
      const newMessage = {
        conversation_id: responseData.conversation_id,
        message: responseData.message,
        message_type: responseData.message_type,
        message_category: responseData.message_category,
        user_id: responseData.user_id,
        message_time: responseData.message_time
      };
      this.messages.unshift(newMessage);
    },
    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    },
    isJwtValid(token) {
      if (!token) return false;

      const payloadBase64 = token.split('.')[1];
      const decodedJson = atob(payloadBase64);
      const decoded = JSON.parse(decodedJson);

      const exp = decoded.exp;
      const now = Date.now().valueOf() / 1000;

      return now < exp;
    },

  }
};
</script>
