<template>
  <Dialog :visible="visible" :modal="true" :closable="false">
    <template #header>
      <h3>My Account</h3>
    </template>
    <div v-if="userDetails">
      <p><strong>First Name:</strong> {{ userDetails.first_name }}</p>
      <p><strong>Last Name:</strong> {{ userDetails.last_name }}</p>
      <p><strong>Gender:</strong> {{ userDetails.gender }}</p>
      <p><strong>User category:</strong> {{ userDetails.category }}</p>

    </div>
    <template #footer>
      <Button label="Close" @click="close" />
    </template>
  </Dialog>
</template>
  
<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { jwtDecode } from 'jwt-decode';



export default {
  name: 'MyAccountModal',
  components: {
    Dialog,
    Button
  },
  computed: {
    userDetails() {
      const details = localStorage.getItem('user_details');
      return details ? JSON.parse(details) : {};
    }
  },
  data() {
    return {
      userInfo: this.decodeUserInfo()
    };
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
    },
    decodeUserInfo() {
      try {
        const jwt = localStorage.getItem('jwt');
        return jwt ? jwtDecode(jwt) : {};
      } catch (error) {
        console.error('Error decoding JWT:', error);
        return {};
      }
    }
  }
};
</script>
  
<style scoped>
.userDetails {
  padding: 20px;
}

.userDetails p {
  margin: 5px 0;
}
</style>
  