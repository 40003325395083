<template>
  <div class="pin-input">
    <InputText v-for="i in 6" :key="i" v-model="displayDigits[i - 1]" :disabled="disabled" maxlength="1"
      :class="['pin-field', { 'invalid-pin': isPinInvalid }]" @keyup="handleKeyup($event, i - 1)"
      @keyup.enter="triggerLogin" ref="pinFieldRefs" @focus="handleFocus($event)" type="tel" />
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';

export default {
  props: {
    disabled: Boolean,
  },
  components: { InputText },
  data() {
    return {
      pinDigits: Array(6).fill(''),
      displayDigits: Array(6).fill(''),
      isPinInvalid: false,
    };
  },
  mounted() {
    this.focusFirstInput();
  },
  methods: {
    focusFirstInput() {
      this.$nextTick(() => {
        let firstInput = this.$refs.pinFieldRefs[0];
        if (firstInput && firstInput.$el) {
          firstInput.$el.focus();
        } else if (firstInput) {
          firstInput.focus();
        }
      });
    },
    moveFocus(index) {
      console.log('moving focus' + index)
    },
    handleFocus(event) {
      event.target.select();
    },
    triggerLogin() {
      this.$emit('submit-pin');
    },
    handleKeyup(event, index) {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
        return;
      }

      this.pinDigits[index] = event.key;
      this.displayDigits[index] = '*';

      if (index < 5 && this.pinDigits[index].length === 1) {
        this.$nextTick(() => {
          let nextInput = this.$refs.pinFieldRefs[index + 1];

          if (nextInput && nextInput.$el) {
            nextInput.$el.focus();
          } else if (nextInput) {
            nextInput.focus();
          }
        });
      }
    },
    resetPinFields() {
      this.pinDigits = Array(6).fill('');
      this.isPinInvalid = true;
      this.$nextTick(() => {
        let firstInput = this.$refs.pinFieldRefs[0];
        if (firstInput && firstInput.$el) {
          firstInput.$el.focus();
        } else if (firstInput) {
          firstInput.focus();
        }
      });
    },

  },
  computed: {
    pin: {
      get() {
        return this.pinDigits.join('');
      },
      set(value) {
        this.pinDigits = value.split('');
      }
    }
  },
  watch: {
    pin(newValue) {
      this.$emit('update:pin', newValue);
    }
  }
};
</script>

<style scoped>


.pin-input {
  display: flex;
  justify-content: space-between;
}

.invalid-pin {
  border: 2px solid red;
}

.pin-field {
  width: 3em;
  text-align: center;
}
</style>
