<template>
  <div class="header">
    <a href="https://continualbot.com" target="_blank" class="footer-link">
      <img src="@/assets/continual-bot-logo.png" alt="Logo" class="logo" />
    </a>
  </div>
</template>
  
<script>
export default {
  name: 'HeaderLogo'
};
</script>
  
<style scoped>
.header {
  padding-top: 20px;
  padding-left: 40px;
  background-color: black;
}

.logo {
  height: 50px;
  /* Adjust size as needed */
}

@media (max-width: 480px) {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    padding-left: 0;
    height: 60px;
  }

  .logo {
    height: 40px;
  }
}
</style>
