<template>
    <Dialog :visible="visible" :modal="true" :closable="false">
        <template #header>
            <h3>Welcome</h3>
        </template>

        <div class="terms-container">
            <Checkbox v-model="acceptTerms" binary="true"></Checkbox>
            <span class="terms-text">I accept the <a href="#" @click="openTermsModal">Terms and Conditions</a></span>
        </div>

        <div class="login-options">
            <Button label="Magic Login" class="p-button-outlined button" @click="showMagicLogin" :disabled="!acceptTerms" />
            <span class="or-text">OR</span>
            <Button label="Login with PIN" class="p-button-outlined button" @click="showPinMethod"
                :disabled="!acceptTerms" />
        </div>

        <div v-if="showPinInput && !isLockedOut && pinButtonPressed && acceptTerms" class="pin-input-container">
            <PinInput :disabled="isLockedOut" ref="pinInputComponent" @update:pin="handlePinUpdate"
                @submit-pin="pinLogin" />
        </div>
        <p v-if="isLockedOut && pinButtonPressed" class="error-message">{{ errorMessage }}</p>

        <div v-if="showMagicLoginInput && !isLockedOut && magicLoginButtonPressed && acceptTerms"
            class="magic-login-container">
            <MagicLoginInput ref="magicLoginInputComponent" @submit-email="handleMagicLogin" :message="magicLoginMessage"
                :isDisabled="magicLoginInputDisabled" />
        </div>

        <p v-if="isMagicLockedOut && magicLoginButtonPressed" class="error-message">{{ magicErrorMessage }}</p>

        <template #footer>
            <Button label="Login" @click="submitLogin"
                v-if="(showPinInput || showMagicLoginInput) && !isLockedOut && acceptTerms" />
        </template>


    </Dialog>
    <TermsAndConditionsModal :visible="showTermsModal" @update:visible="showTermsModal = $event" />
</template>

  
<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import axios from 'axios';
import PinInput from './PinInput.vue';
import termsAndConditionsText from '@/assets/tac.js';
import TermsAndConditionsModal from './TermsAndConditionsModal.vue';
import MagicLoginInput from './MagicLoginInput.vue';



export default {
    name: 'LoginModal',
    components: {
        Dialog,
        Button,
        PinInput,
        Checkbox,
        TermsAndConditionsModal,
        MagicLoginInput
    },
    mounted() {
        this.checkLockout();
        this.checkMagicLockout();
        const route = this.$route;
        const authToken = route.params.auth_token;
        if (authToken) {
            this.magicLogin(authToken);
        }
    }, props: {
        visible: Boolean,
        auth_token: String
    },
    data() {
        return {
            pin: '',
            showPinInput: false,
            acceptTerms: false,
            showTermsModal: false,
            termsAndConditionsText,
            failedAttempts: 0,
            isLockedOut: false,
            errorMessage: '',
            magicLoginButtonPressed: false,
            pinButtonPressed: false,
            magicFailedAttempts: 0,
            isMagicLockedOut: false,
            magicErrorMessage: '',
            showMagicLoginInput: false,
            magicLoginMessage: '',
            magicLoginInputDisabled: false
        };
    },
    watch: {
        '$route.params.auth_token'(newToken) {
            if (newToken) {
                this.magicLogin(newToken);
            }
        }
    },

    methods: {
        async submitLogin() {
            if (this.pinButtonPressed) {
                await this.pinLogin();
            } else if (this.magicLoginButtonPressed) {
                const email = this.$refs.magicLoginInputComponent.email;
                await this.handleMagicLogin(email);
            }
        },
        openTermsModal() {
            this.showTermsModal = true;
        },
        handlePinUpdate(pin) {
            this.pin = pin; // Update the pin with the value from PinInput
        },
        async handleMagicLogin(email) {
            if (this.isMagicLockedOut) {
                console.log('Magic login attempts locked out.');
                return;
            }
            try {
                const response = await axios.post(this.$config.apiBaseUrl + '/auth/magiclinkreq', { email });
                if (response.data && response.data.success) {
                    this.magicLoginMessage = response.data.message;
                    this.magicLoginInputDisabled = true;
                    // Handle successful response
                    console.log(response.data.message);
                    // Redirect to homepage
                    setTimeout(() => { window.location.href = '/'; }, 5000);
                } else {
                    // Handle error
                    this.magicLoginMessage = response.data.error;
                    this.magicLoginInputDisabled = true;
                    this.handleMagicLoginFailure();
                    console.error(this.magicLoginMessage);
                }
            } catch (error) {
                console.error('Error during magic login request:', error);
                this.magicLoginMessage = 'Error during magic login request';
                this.handleMagicLoginFailure();
            }
            if (this.magicFailedAttempts >= 3) {
                this.isMagicLockedOut = true;
                this.magicErrorMessage = "Too many failed attempts. Please try again in 15 minutes.";
                const lockoutTime = Date.now() + (15 * 60 * 1000);
                localStorage.setItem('magicLockoutTime', lockoutTime);
                this.initiateMagicLockout(lockoutTime);
            }
        },
        handleMagicLoginFailure() {
            this.magicFailedAttempts++;
        },
        checkMagicLockout() {
            const lockoutTime = localStorage.getItem('magicLockoutTime');
            if (lockoutTime && Date.now() < lockoutTime) {
                this.initiateMagicLockout(lockoutTime);
            } else {
                this.clearMagicLockout();
            }
        },
        initiateMagicLockout(lockoutTime) {
            this.isMagicLockedOut = true;
            this.magicErrorMessage = "Too many failed attempts. Please try again in 15 minutes.";

            const lockoutDuration = lockoutTime - Date.now();
            setTimeout(() => {
                this.clearMagicLockout();
            }, lockoutDuration);
        },
        clearMagicLockout() {
            this.isMagicLockedOut = false;
            this.magicFailedAttempts = 0;
            this.magicErrorMessage = '';
            localStorage.removeItem('magicLockoutTime');
        },
        async magicLogin(authToken) {
            try {
                const response = await axios.post(this.$config.apiBaseUrl + '/auth/' + authToken, {}, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                // Handle the response for token-based authentication
                if (response.data && response.data.jwt) {
                    const { jwt, user_details, conversation_id, conversation } = response.data;
                    this.$emit('login-success', { jwt, user_details, conversation_id, conversation });
                    this.$router.push('/');
                } else {
                    console.error('Invalid token auth');
                }
            } catch (error) {
                console.error('Magic login failed:', error);
            }
        },
        showMagicLogin() {
            this.showMagicLoginInput = true;
            this.magicLoginButtonPressed = true;
            this.pinButtonPressed = false;
        },
        showPinMethod() {
            this.showPinInput = true;
            this.pinButtonPressed = true;  // Indicate that the PIN login button has been pressed
            this.magicLoginButtonPressed = false;
        },
        async pinLogin() {
            if (this.isLockedOut) {
                // If locked out, do not proceed
                console.log('Login attempts locked out.');
                return;
            }
            try {
                const response = await axios.post(this.$config.apiBaseUrl + '/auth', { pin: this.pin });
                if (response.data && response.data.jwt) {
                    const { jwt, user_details, conversation_id, conversation } = response.data;
                    this.$emit('login-success', { jwt, user_details, conversation_id, conversation });
                    this.failedAttempts = 0; // Reset failed attemptss                    
                    this.$emit('close-modal');
                } else {
                    this.handleLoginFailure();
                    console.error('Invalid PIN auth');
                }
            } catch (error) {
                console.error('Login failed:', error);
                this.handleLoginFailure();
            }
            if (this.failedAttempts >= 3) {
                this.isLockedOut = true;
                this.errorMessage = "Too many failed attempts. Please try again in 15 minutes.";
                const lockoutTime = Date.now() + (15 * 60 * 1000); // Current time + 15 minutes
                localStorage.setItem('lockoutTime', lockoutTime);
                localStorage.setItem('failedAttempts', this.failedAttempts);
                this.initiateLockout(lockoutTime);
            }
        },
        handleLoginFailure() {
            if (this.pinButtonPressed) {
                this.$refs.pinInputComponent.resetPinFields();
                this.$emit('login-failure');
                this.$emit('invalid-pin');
            }
            this.failedAttempts++;
        },
        checkLockout() {
            const lockoutTime = localStorage.getItem('lockoutTime');
            if (lockoutTime && Date.now() < lockoutTime) {
                this.initiateLockout(lockoutTime);
            } else {
                this.clearLockout();
            }
        },
        initiateLockout(lockoutTime) {
            this.isLockedOut = true;
            this.errorMessage = "Too many failed attempts. Please try again in 15 minutes.";

            const lockoutDuration = lockoutTime - Date.now();
            setTimeout(() => {
                this.clearLockout();
            }, lockoutDuration);
        },
        clearLockout() {
            this.isLockedOut = false;
            this.failedAttempts = 0;
            this.errorMessage = '';
            localStorage.removeItem('lockoutTime');
            localStorage.removeItem('failedAttempts');
        }
    }
};
</script>

<style scoped>
.error-message {
    color: var(--secondary-color);
    margin-top: 20px;
    text-align: center;
}

.terms-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.terms-text {
    margin-left: 10px;
}

.terms-text a {
    color: var(--text-color);
    cursor: pointer;
    font-weight: bold;
}

.dialog {
    width: 30vw;
    /* Moved from inline style */
}

.login-options {
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    height: 40px;
}

.or-text {
    margin: 10px 0;
    /* Vertical margin around the "OR" text */
}

@media (min-width: 600px) {
    .login-options {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .or-text {
        margin: 0 15px;
        /* Horizontal margin around the "OR" text on wider screens */
    }
}

.magic-login-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pin-input-container {
    margin-top: 40px;
}

.pin-input {
    display: flex;
    justify-content: space-between;
}

.pin-field {
    width: 3em;
    text-align: center;
}
</style>
