const termsAndConditionsText = `
Welcome to ContinualBot. <br> 
By using our service, you agree to the following terms:<br><br>

1. Service Description: ContinualBot generates multi-language answers to queries, based on a predefined knowledge base. It can make mistakes.<br>

2. Data Use and Privacy: The conversations are stored to improve the service. These conversations are utilized exclusively for training purposes and enhancing the chatbot's capabilities. The ContinualBot framework possesses the ability to completely anonymize conversations. However, this feature is disabled in this instance due to the necessity of an impersonation use case. <br>

3. Confidentiality: We uphold strict confidentiality of your interactions. Under no circumstances will your conversations be shared with external parties.<br>

4. User Responsibility: Users are responsible for the questions they pose to the chatbot. We encourage respectful and constructive inquiries. <br>

5. Changes to Terms: We reserve the right to modify these terms at any time. Continued use of the service implies acceptance of the revised terms. <br>

6. Acceptance: By accepting these terms, you acknowledge understanding and agreeing to comply with the conditions outlined herein. <br><br>

Date last updated: 2024-01-07
`;

export default termsAndConditionsText;
