<template>
    <Dialog :visible="visible" position="left" :modal="true" :closable="false" class="terms-dialog">
        <template #header>
            <h3>Terms and Conditions</h3>
        </template>
        <p v-html="termsAndConditionsText"></p>
        <template #footer>
            <Button label="OK" @click="close" />
        </template>
    </Dialog>
</template>
  
<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import termsAndConditionsText from '@/assets/tac.js';

export default {
    components: {
        Dialog,
        Button
    },
    props: {
        visible: Boolean
    },
    methods: {
        close() {
            this.$emit('update:visible', false);
        }
    },
    data() {
        return {
            termsAndConditionsText
        };
    }
};
</script>
  
<style scoped>
.terms-dialog {
    width: 75vw;
}
</style>
  